import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_c3589ac1fdbf1aeed65a63e47d39a5fc/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/node_modules/.pnpm/@una-ui+content@45.1.0_@parcel+watcher@2.5.1_@types+node@22.13.14_@unhead+vue@2.0.5_vue_fae9c7fcb8c9ec2f0854192ca3909d8d/node_modules/@una-ui/content/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}