import revive_payload_client_LAthg7myY4SJs6VLAnb7OD8eOMR_VKkAqiH1VWCaVJw from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_c3589ac1fdbf1aeed65a63e47d39a5fc/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_r55D2q5K8VXYQ78VLZyFJprfxE9Qo1SqN_0KmAQRIgA from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_c3589ac1fdbf1aeed65a63e47d39a5fc/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rsQDk4I7pVKec8AwH1U5dd8Vhd4hO__xqYhI_2yolk8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_c3589ac1fdbf1aeed65a63e47d39a5fc/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_92n7s_caZhwB5dyDACki0A_vSesWFD0nJe8LgjvCE6Q from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.1.4_magicast@0.3.5_vue@3.5.13_typescript@5.6.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_BnOLwaypX6bXfcNJdo4_xGCqTTm8rv1Al3VbsdnkdLY from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_c3589ac1fdbf1aeed65a63e47d39a5fc/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5EhS4eU2OWJbKbS92j90s8QoILPnvQ1rT9ReQwiF1xc from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_c3589ac1fdbf1aeed65a63e47d39a5fc/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Z8EzbPvd_fAO_NLN2BWtdOQCBrIVIOgCdv_zHRxfDBY from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_c3589ac1fdbf1aeed65a63e47d39a5fc/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4zfl8hUDUyZkcvzkM4_AXIK46mkhodfsoSwzge6pNpU from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_c3589ac1fdbf1aeed65a63e47d39a5fc/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/docs/.nuxt/components.plugin.mjs";
import prefetch_client_IXCZPCRXHvojD3XP_mHlPNfNuh1lC4Jhye4fSfLif70 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_c3589ac1fdbf1aeed65a63e47d39a5fc/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_0Yx_AolanxEDPuGciKK9MqH5sH51pmpERVIrakZH_fo from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.5_@types+node@22.13.14_jiti@2.4.2_sass@1.86.0_95e11df631f301d7f8d5e05fb18609f1/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import documentDriven_miiVaVD9_N88eImkdqoNhIlfrnntim1OBG4jp5kObbc from "/vercel/path0/node_modules/.pnpm/@nuxt+content@2.13.4_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5_nuxt@3.16.2_@parcel+watcher_e973aaf6ee6f3641290c6e5d412a714c/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import theme_client_rfbVbgWaCwi7ZJMGerocuZgluqwuXtmrKBv2vWIBFuU from "/vercel/path0/packages/nuxt/src/runtime/plugins/theme.client.ts";
import plugin_client_sQsoN3ShCY3w7TTUhDo6ZgZURKOa9c7Ot_XTJsCGlO0 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII from "/vercel/path0/docs/.nuxt/unocss.mjs";
import analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U from "/vercel/path0/docs/plugins/analytics.client.ts";
export default [
  revive_payload_client_LAthg7myY4SJs6VLAnb7OD8eOMR_VKkAqiH1VWCaVJw,
  unhead_r55D2q5K8VXYQ78VLZyFJprfxE9Qo1SqN_0KmAQRIgA,
  router_rsQDk4I7pVKec8AwH1U5dd8Vhd4hO__xqYhI_2yolk8,
  _0_siteConfig_92n7s_caZhwB5dyDACki0A_vSesWFD0nJe8LgjvCE6Q,
  payload_client_BnOLwaypX6bXfcNJdo4_xGCqTTm8rv1Al3VbsdnkdLY,
  navigation_repaint_client_5EhS4eU2OWJbKbS92j90s8QoILPnvQ1rT9ReQwiF1xc,
  check_outdated_build_client_Z8EzbPvd_fAO_NLN2BWtdOQCBrIVIOgCdv_zHRxfDBY,
  chunk_reload_client_4zfl8hUDUyZkcvzkM4_AXIK46mkhodfsoSwzge6pNpU,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_IXCZPCRXHvojD3XP_mHlPNfNuh1lC4Jhye4fSfLif70,
  plugin_0Yx_AolanxEDPuGciKK9MqH5sH51pmpERVIrakZH_fo,
  documentDriven_miiVaVD9_N88eImkdqoNhIlfrnntim1OBG4jp5kObbc,
  theme_client_rfbVbgWaCwi7ZJMGerocuZgluqwuXtmrKBv2vWIBFuU,
  plugin_client_sQsoN3ShCY3w7TTUhDo6ZgZURKOa9c7Ot_XTJsCGlO0,
  unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII,
  analytics_client_D__0bhX73uC0vhcsTxv5CvOp30_GkDRY3Sfq7hDlz_U
]